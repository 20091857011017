export interface KommDataModel {
  emails: KommDataItem[];
  mobileNumbers: KommDataItem[];
  phoneNumbers?: KommDataItem[];
  participations: ProgramParticipationItem[];
  kommDataAddLink: KommDataAddLink;
}

export interface KommDataBackendModel {
  email: KommDataItem;
  mobileNumber: KommDataItem;
  phoneNumber: KommDataItem;
  registrationItem: RegistrationResponse;
  trackingSource: string;
  trackingSourceDetail: string;
  identified: boolean;
}

export interface AuthorizedRepresentative {
  firstname: string;
  lastname: string;
  role: string;
}

export interface KommDataItem {
  type: string;
  value: string;
  deletableType: DeletableType;
  maz?: boolean;
  kommDataType?: KommDataType
}

export enum DeletableType {
  DELETABLE = 'DELETABLE', MARKED_FOR_UPDATE = 'MARKED_FOR_UPDATE', PRIO_FLAG = 'PRIO_FLAG', LINKED_WITH_PROGRAMM = 'LINKED_WITH_PROGRAMM'
}

export enum KommDataType {
  PRIVATE = 'PRIVATE', BUSINESS = 'BUSINESS', PRIVATEBUSINESS = 'PRIVATEBUSINESS', UNKNOWN = 'UNKNOWN'
}

export enum KommDataAddLink {
  DIGITAL_PROFILE = 'DIGITAL_PROFILE', PROGRAM_PARTICIPATIONS = 'PROGRAM_PARTICIPATIONS', INLINE = 'INLINE', NONE = 'NONE'
}

export interface RegistrationResponse {
  email: string;
  mobileNumber: string;
  authorizedRepresentativeRequest: AuthorizedRepresentative;
}

export interface ProgramParticipationItem {
  type: ProgramParticipationType;
  status: ProgramParticipationStatus;
  emails: KommDataItem[];
  mobileNumbers: KommDataItem[];
  registrationDetails?: RegistrationDetails;
}

export enum ProgramParticipationType {
  SBS = 'SBS', WEWE = 'WEWE', MAZ = 'MAZ', AVP = 'AVP', ESB = 'ESB', UNKNOWN = 'UNKNOWN', DEWE = 'DEWE', DEWE_PLUS = 'DEWE_PLUS'
}

export enum ProgramParticipationStatus {
  GREEN = 'GREEN', RED = 'RED', YELLOW = 'YELLOW', GREY = 'GREY', UNKNOWN = 'UNKNOWN'
}

export enum RegistrationStatus {
  REG_MAIL_SENT, REG_PROCESS_STARTED, ACTIVATION_CODE_PENDING, UNKNOWN
}

export interface RegistrationDetails {
  status: RegistrationStatus;
  date: string;
}

export interface ProgramStatus {
  id: ProgramParticipationStatus;
  iconClass: string;
  iconName: string;
}

export interface DisplayProgramParticipation {
  type: ProgramParticipationType;
  status: ProgramStatus;
}
