import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../core/services/auth/auth.service';
import * as _ from 'lodash-es';
import {AgentService} from '../core/services/auth/agent.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthService,
    private readonly agentService: AgentService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToPerform = req;

    if (this.agentService.hasToken()) {
      const authHeaderObj = {};
      const authorizationKey = 'Authorization';
      authHeaderObj[authorizationKey] = this.getModifiedTokenForHeader();
      this.addAgentHeaders(authHeaderObj);
      requestToPerform = req.clone({setHeaders: authHeaderObj});
    }

    return next.handle(requestToPerform);
  }


  private addAgentHeaders(headersObj) {
    if (this.authService.isAgent()) {
      headersObj['X-AGENT-CONTEXT-DATA'] = this.getAgentContextData();
    }
  }

  private getAgentContextData(): string {
    const agentContextData = {
      absCustomerId: this.agentService.getAbsCustomerId(),
      amisPersonId: this.agentService.getAmisPersonId(),
      agentNumber: this.agentService.getVTNR()
    };

    return JSON.stringify(agentContextData);
  }

  getModifiedTokenForHeader(): string {
    const token = this.agentService.getToken();
    if (!_.startsWith(token, 'Bearer ')) {
      return `Bearer ${token}`;
    }
    return token;
  }


}
