import {Injectable} from '@angular/core';
import {KommDataModel, ProgramParticipationStatus, ProgramParticipationType} from '../../core/models/kommdata.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramUtilService {

  constructor() {
  }

  public hasMaz(kommData: KommDataModel): boolean {
    return this.hasProgram(kommData, ProgramParticipationType.MAZ);
  }

  public hasWEWE(kommData: KommDataModel): boolean {
    return this.hasProgram(kommData, ProgramParticipationType.WEWE);
  }

  public hasDEWE(kommData: KommDataModel): boolean {
    return this.hasProgram(kommData, ProgramParticipationType.DEWE) || this.hasProgram(kommData, ProgramParticipationType.DEWE_PLUS);
  }

  private hasProgram(kommData: KommDataModel, programType: ProgramParticipationType) {
    return kommData.participations.find(participation => participation.type === programType)?.status
      === ProgramParticipationStatus.GREEN;
  }
}
