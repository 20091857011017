import {Injectable} from '@angular/core';
import {AuthState} from '../../models/auth-info.model';

import {AppStateService} from '../app-state.service';
import {OmModel, Section, ServiceOrigin, UserType} from './model/auth.model';

export const AUTH_STATE_KEY = 'auth-state';
export const AUTH_FROM_SH = 'dsm-servicehub:auth';

interface ServiceHubAuthInfo {
  token: string;
  ng: UserType.VT | UserType.MK;
  bid?: string;
  amisSessionId?: string;
  amisPersonId?: string;
  absCustId?: string;
  groupCustomerIds?: string[];
  omModel?: OmModel;
  fk?: Section.KRAFT | Section.SACH | Section.LEBEN | Section.KRANKEN | Section.SCHADEN;
  aa?: ServiceOrigin.AMIS | ServiceOrigin.SERVICE_ABS | ServiceOrigin.SERVICE_BAUFI | ServiceOrigin.SERVICE_LEBEN |
    ServiceOrigin.SERVICE_SACH | ServiceOrigin.SCHADEN | ServiceOrigin.MAKLERPORTAL | ServiceOrigin.NEBENBERUFSVERTRETER;
  vtnr?: string;
  email?: string;
  sig: string;
  prodTestUserDss?: boolean;
  amisupd?: string;
  amisTokenExpired?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private readonly appStateService: AppStateService) {

    const authState = sessionStorage.getItem(AUTH_STATE_KEY);
    const authFromSH = JSON.parse(sessionStorage.getItem(AUTH_FROM_SH)) as ServiceHubAuthInfo;

    if (authFromSH && authState && AuthState[authState] === AuthState.AGENT) {

      const auth = appStateService.authInfo;
      auth.aa = authFromSH.aa;
      auth.fk = authFromSH.fk;
      auth.ng = authFromSH.ng;
      auth.omModel = authFromSH.omModel;
      auth.vtnr = authFromSH.vtnr;

      auth.authState = AuthState[authState];
    } else {
      this.clearAuth();
    }
  }

  isAgent(): boolean {
    return this.appStateService.authInfo.authState === AuthState.AGENT;
  }

  isAuthed(): boolean {
    return this.appStateService.authInfo.authState !== AuthState.NONE;
  }

  clearAuth() {
    const auth = this.appStateService.authInfo;
    auth.authState = AuthState.NONE;
    auth.aa = null;
    auth.fk = null;
    auth.ng = null;
    auth.omModel = null;
    auth.vtnr = null;
  }

  getAAFromAuth(): string {
    return this.appStateService.authInfo.aa;
  }
}



