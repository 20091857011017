import {ContextType} from './auth.model';

export enum UserGroupModel {
  VT = 'VT',
  VTNOW = 'VTNOW',
  MK = 'MK',
  NBV = 'NBV',
  BANK = 'BANK',
  DVE = 'DVE'
}
